<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="" style="width: 200px; margin-right: 20px">
        <el-input
          type="text"
          placeholder="输入单据号"
          v-model="searcForm.no"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="" style="width: 200px; margin-right: 20px">
        <el-input
          type="text"
          placeholder="输入客户名称/编号"
          v-model="searcForm.param"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="录单日期" style="width: 440px">
        <el-date-picker
          v-model="pickerDate"
          type="daterange"
          range-separator="至"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          :picker-options="pickerOptions"
          end-placeholder="结束日期"
          :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="收款状态:" style="width: 280px">
        <el-select v-model="searcForm.payStatus" placeholder="" clearable>
          <el-option label="未退款" :value="0"> </el-option>
          <el-option label="部分退款" :value="1"> </el-option>
          <el-option label="全部退款" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="F">
          <el-button type="primary" @click="searchForm">查询</el-button>
        </div>
      </el-form-item>
    </el-form>
    <div class="FSB" style="margin-bottom: 15px">
      <div></div>
      <div>
        <el-button type="primary" @click="addSaleList">新增</el-button>
        <el-button type="primary" @click="handleDownload" plain>导出</el-button>
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="supplierCode" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="watchList(scope.row.id)"
            >查看</el-button
          >
          <el-button type="text" @click="deleteList(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="entryDate" label="单据日期" align="center"></el-table-column>
      <el-table-column prop="no" label="单据号" align="center"></el-table-column>
      <el-table-column prop="name" label="客户" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}({{ scope.row.code }})
        </template>
      </el-table-column>
      <el-table-column prop="prepaidPrice" label="退款金额" align="center">
        <template slot-scope="scope">
          {{ (scope.row.prepaidPrice / 100).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="createUserName" label="制单人" align="center"></el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="changePageSize"
      @current-change="changeCurrentPage"
      :current-page="searcForm.pageNum"
      :page-size="searcForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { datatimestatus } from "@/until/auth";
import { getWebOrderRef, delRefWebOrder } from "@/api/sale";
export default {
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 20,
        no: "",
        param: "",
        payStatus: "",
        startDate: "",
        endDate: "",
      },
      total: 0,
      tableData: [],
      pickerDate: [],
      supplierData: [],
      pickerOptions: null,
    };
  },
  created() {
    this.getWebOrderRef();
    this.getDate();
    this.datatimestatus();
  },
  watch: {
    pickerDate(val) {
      if (!val) {
        this.searcForm.startDate = "";
        this.searcForm.endDate = "";
      } else {
        this.searcForm.startDate = val[0];
        this.searcForm.endDate = val[1];
      }
    },
  },
  methods: {
    watchList(id) {
      this.$router.push({
        path: "/sale/retailReturnList/watchSaleReturn",
        query: { id },
      });
    },
    async deleteList(id) {
      let res = await delRefWebOrder(id);
      if (res.code == 1) {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.getWebOrderRef();
      } else {
        this.$message({
          type: "warning",
          message: res.msg,
        });
      }
    },
    // 导出单个表头
    handleDownload() {
      import("@/until/Export2Excel").then((excel) => {
        const header = ["单据日期", "单据号", "客户", "退款金额", "制单人"]; // 最后一行的表头数据
        const filterVal = [
          "entryDate",
          "no",
          "name",
          "prepaidPrice",
          "createUserName",
        ];
        // 这里要注意 header  和 filterVal 的数组长度一定要相等，否则会报错
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          // multiHeader,
          header,
          // merges,
          data,
          filename: "零售退货单",
        });
      });
    },
    // 拼接数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    addSaleList() {
      this.$router.push({
        path: "/sale/retailReturnList/addSaleReturn",
      });
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      //   this.GetList();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      //   this.GetList();
    },
    async getWebOrderRef() {
      let params = this.searcForm;
      let res = await getWebOrderRef(params);
      if (res.code == 1) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    searchForm() {
      this.searcForm.pageNum = 1;
      this.getWebOrderRef();
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      if (this.openAccountFlag) {
        var reg = sessionStorage.getItem("createDate").split(" ")[0];
        var defaultDate = [`${reg}`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${reg}`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      } else {
        var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${year}-${month}-01`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      }
      this.pickerDate = defaultDate;
    },
    datatimestatus() {
      this.pickerOptions = datatimestatus();
    },
  },
};
</script>

<style lang='scss' scoped>
.F {
  display: flex;
}
.FSB {
  display: flex;
  justify-content: space-between;
}
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
}
</style>